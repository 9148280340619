import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from 'firebase/auth';
import HamburgerMenu from '../HamburgerMenu';
import janazaInline from "../../../styles/drawable/janaza_inline.png"
import janazaLogo from "../../../styles/drawable/janaza_logo.png";
import BorderButton from '../../buttons/BorderButton';
import { listener_prayersCount } from '../../../data/repository/firebasePrayers';
import InfoBarHeaderMenu from './InfoBarHeaderMenu';

interface TwoHeaderMenuProps {
    classNames: string,
    currentUser?: User | null,
    isMenuOpen?: boolean,
    isHamburgerVisible?: boolean,
    isSignInButtonVisible?: boolean,
    isSignUpButtonVisible?: boolean,
    handleSignOut?: () => any,
    setMenuOpen?: (isOpen: boolean) => any,
}

const TwoHeaderMenu = ({classNames, currentUser, isMenuOpen, isHamburgerVisible, isSignInButtonVisible, isSignUpButtonVisible, handleSignOut, setMenuOpen}: TwoHeaderMenuProps) => {

    const [prayerCount, setPrayersCount] = useState(0);

    const navigate = useNavigate()

    useEffect(() => {
        const fetchPrayersListener = async () => {
            const data = await listener_prayersCount;
            setPrayersCount(data.data().count)
        }
        fetchPrayersListener()
    })

    return (
        <header className={`${classNames} fixed z-50 flex flex-col w-full`}> {/* Menu */}
            <InfoBarHeaderMenu count={prayerCount}/>
            <div className="border-b-2 border-blue_sky_90 bg-blue_sky w-full px-4 h-fit py-3 lg:px-12 xl:px-16 xl:z-10">
                <div className="flex flex-row justify-between w-full z-20 lg:static 2xl:m-auto"> {/* Inner Menu */}
                    <div onClick={() => navigate("/")} className="flex flex-row cursor-pointer self-center lg:static">
                        <img src={janazaLogo} alt="" className="h-6 me-1"/>
                        <img src={janazaInline} alt="" className="h-6 invert"/>
                    </div>
                    { (isHamburgerVisible && (setMenuOpen != null) && isMenuOpen) &&
                        <HamburgerMenu
                            className=""
                            onClick={() => setMenuOpen(!isMenuOpen)}
                            isClicked={isMenuOpen}
                        />
                    }
                    { (isSignInButtonVisible) &&
                        <button
                            onClick={() => navigate("/signin")}
                            className="border-[2px] border-primary_color_10 text-primary_color_10 self-center font-medium text-sm rounded-full hover:bg-black hover:bg-opacity-10 transition-colors px-4 py-3"
                        >{`Se connecter`}</button>    
                    }
                    { (isSignUpButtonVisible) &&
                        <button
                            onClick={() => navigate("/signup")}
                            className="border-[2px] border-primary_color_10 text-primary_color_10 self-center font-medium text-sm rounded-full hover:bg-black hover:bg-opacity-10 transition-colors px-4 py-3"
                        >{`S'inscrire`}</button>                    }
                </div>
            </div>
        </header>
    )
}

export default TwoHeaderMenu;