import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { User } from 'firebase/auth';
import HamburgerMenu from '../HamburgerMenu';
import janazaInline from "../../../styles/drawable/janaza_inline.png"
import janazaLogo from "../../../styles/drawable/janaza_logo.png";
import BorderButton from '../../buttons/BorderButton';
import InfoBarHeaderMenu from './InfoBarHeaderMenu';
import { listener_prayersCount } from '../../../data/repository/firebasePrayers';
import { onSnapshot } from 'firebase/firestore';
import { ArrowBackIosRounded } from '@mui/icons-material';

interface HeaderMenuProps {
    classNames: string,
    currentUser?: User | null,
    isBackButtonVisible?: boolean
    isSeeJanazaButtonVisible?: boolean,
    isAddJanazaButtonVisible?: boolean,
    isMenuOpen?: boolean,
    isHamburgerVisible?: boolean,
    isSignButtonVisible?: boolean,
    handleSignOut?: () => any,
    setMenuOpen?: (isOpen: boolean) => any,
}

const HeaderMenu = ({classNames, currentUser, isBackButtonVisible, isSeeJanazaButtonVisible, isAddJanazaButtonVisible, isMenuOpen, isHamburgerVisible, isSignButtonVisible, handleSignOut, setMenuOpen}: HeaderMenuProps) => {

    const [prayerCount, setPrayersCount] = useState(0);

    const navigate = useNavigate()

    useEffect(() => {
        const fetchPrayersListener = async () => {
            const data = await listener_prayersCount;
            setPrayersCount(data.data().count)
        }
        fetchPrayersListener()
    })
    


    return (
        <header className={`${classNames} fixed z-50 flex flex-col w-full`}> {/* Menu */}
            <InfoBarHeaderMenu count={prayerCount}/>
            <div className="border-b-2 border-blue_sky_90 bg-blue_sky w-full px-4 h-fit py-3 lg:px-12 xl:px-16 xl:z-10">
                <div className="flex flex-row relative justify-between w-full z-20 lg:static 2xl:m-auto"> {/* Inner Menu */}
                    { isBackButtonVisible ? (
                        <div onClick={() => navigate(-1)} className="h-48px self-center lg:hidden">
                            <ArrowBackIosRounded className="text-primary_color_10"/>
                        </div>
                    ) : (
                        <div onClick={() => navigate(-1)} className="block h-[44px] lg:hidden"/>
                    )}
                    <div onClick={() => navigate('/')} className="flex flex-row cursor-pointer absolute -translate-x-1/2 left-1/2 self-center lg:static lg:translate-x-0">
                        <img src={janazaLogo} alt="" className="h-6 me-1"/>
                        <img src={janazaInline} alt="" className="h-6 invert"/>
                    </div>
                    <div className='flex flex-row'>
                        <div className="hidden lg:flex lg:flex-row lg:border-[2px] border-primary_color_10 rounded-full">
                            { isAddJanazaButtonVisible &&
                                <button
                                    onClick={() => currentUser ? navigate("/add-prayer") : navigate("/signin")}
                                    className="hidden text-primary_color_10 self-center me-0.5 font-medium text-sm rounded-full hover:bg-black hover:bg-opacity-10 transition-colors px-4 py-3 lg:block"
                                >
                                    {`Ajouter une Janaza`}
                                </button>
                            }
                            { isSeeJanazaButtonVisible &&
                                <button
                                    onClick={() => navigate("/prayers")}
                                    className="hidden text-primary_color_10 self-center me-0.5 font-medium text-sm rounded-full hover:bg-black hover:bg-opacity-10 transition-colors px-4 py-3 lg:block"
                                >
                                    {`Voir les Janaza`}
                                </button>
                            }
                            { (isSignButtonVisible) &&
                                <button
                                    onClick={() => navigate("/signin")}
                                    className="text-primary_color_10 self-center me-0.5 font-medium text-sm rounded-full hover:bg-black hover:bg-opacity-10 transition-colors px-4 py-3"
                                >{`S'inscrire`}</button>
                                // <BorderButton type="button" small={true} text="S'inscrire" onClick={() => navigate("/signup")} className=""/>
                            }
                            <div className='hidden lg:flex lg:flex-row'>
                                { !currentUser &&
                                    <>
                                        <button
                                            onClick={() => navigate("/signin")}
                                            className="text-primary_color_10 self-center me-0.5 font-medium text-sm rounded-full hover:bg-black hover:bg-opacity-10 transition-colors px-4 py-3"
                                        >Connexion</button>
                                        {/* <button
                                            onClick={() => navigate("/signup")}
                                            className="text-primary_color_10 self-center  font-medium text-sm rounded-full hover:bg-black hover:bg-opacity-10 transition-colors px-4 py-3"
                                        >Inscription</button> */}
                                    </>
                                }
                            </div>
                        </div>
                        <ul className="hidden lg:flex flex-row">
                            { (currentUser?.uid) &&
                                <button
                                    onClick={handleSignOut}
                                    className="text-primary_color_10 self-center me-0.5 font-medium text-sm rounded-full hover:bg-black hover:bg-opacity-10 transition-colors px-4 py-3"
                                >Se déconnecter</button>
                            }  
                        </ul>
                        { (isHamburgerVisible && (setMenuOpen != null) && (isMenuOpen != null)) &&
                                <HamburgerMenu
                                    className=""
                                    onClick={() => setMenuOpen(!isMenuOpen)}
                                    isClicked={isMenuOpen}
                                />
                            }
                    </div>   
                </div>
            </div>
        </header>
    )
}

export default HeaderMenu;