import { Prayer as PrayerModel } from "../../models/prayer";
import { app, auth, getUser } from "./firebaseAuth"
import { addDoc, collection, deleteDoc, doc, getCountFromServer, getDocs, getFirestore, increment, arrayUnion, query, updateDoc, where } from "firebase/firestore";

const firestore = getFirestore(app);

const primaryPrayerPath = "pray";

export const onetime_prayerDocs = getDocs(query(collection(firestore, primaryPrayerPath), where("begin_date", ">", new Date().toISOString())));

export const listener_prayerDocs = query(collection(firestore, primaryPrayerPath), where("begin_date", ">", new Date().toISOString()));

export const listener_prayersCount = getCountFromServer(collection(firestore, primaryPrayerPath));

export const addPrayer = async (
    prayer: PrayerModel
) => {
    const currentUser = auth.currentUser

    if (!currentUser)
        return

    const getUserData = await getUser(currentUser?.uid)

    if (!getUserData)
        return

    const currentUserData = getUserData?.data()

    return await addDoc(collection(firestore, primaryPrayerPath), {
        user_id: prayer.user_id,
        category: currentUserData?.organisation,
        phone: prayer.phone,
        name: prayer.name,
        nameLowercaseArray: prayer.nameLowercaseArray,
        sexe: prayer.sexe,
        date: prayer.date,
        hour: prayer.hour,
        address: prayer.address,
        addressConvoi: prayer.address_convoi,
        moreInfos: prayer.more_infos,
        convoi: prayer.convoi,
        hourConvoy: prayer.hourConvoy,
        prayName: prayer.pray_name,
        view_count: prayer.view_count,
        user_views: prayer.user_views,
        location: prayer.location,
        locationConvoi: prayer.location_convoi,
        addedAt: prayer.added_at,
        begin_date: prayer.begin_date, // To Transform to ZonedDateTime to new Date()
        is_updated: prayer.is_updated,
        updated_at: prayer.updated_at
    }).then(docRef => {
        updateDoc(docRef, {id: docRef.id})
    })
}

export const deletePrayer = async (
    prayerId: string
) => {
    if (prayerId != '')
    return await deleteDoc(doc(firestore, primaryPrayerPath, prayerId))
}

export const updateCountView = async (
    prayerId: string,
    userId: string
) => {
    if (prayerId != '') {
        return await updateDoc(doc(firestore, primaryPrayerPath, prayerId), {
            view_count: increment(1),
            user_views: arrayUnion(userId)
        })
    }
}
