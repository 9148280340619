import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

import playStoreLogo from "../../styles/drawable/google_play_badge.png";
import phoneHome from "../../styles/drawable/phone_home.png";
import phoneHomeCut from "../../styles/drawable/phone_home_cut.png";
import phoneAddPrayer from "../../styles/drawable/phone_add_prayer.png";
import phoneMap from "../../styles/drawable/phone_map.png";
import phoneLearn from "../../styles/drawable/phone_learn.png";

import PlainButton from "../../components/buttons/PlainButton";
import BorderButton from "../../components/buttons/BorderButton";
import HeadTitle from "../../components/titles/HeadTitle";
import SecondaryTitle from "../../components/titles/SecondaryTitle";
import PhoneWithBackground from "../../components/PhoneWithBackground";
import MenuOverlay from "../../components/navigation/MenuOverlay";
import timeout from "../../utils/timeout";
import { toast } from "sonner";
import FooterJanaza from "../../components/commons/FooterJanaza";
import HeaderMenu from "../../components/navigation/headerMenu/HeaderMenu";
import { LoadingOverlay } from "../../components/commons/LoadingOverlay";

const App: React.FunctionComponent = (props) => {
    const navigate = useNavigate();

    const [isMenuOpen, setMenuOpen] = useState(false)

    const {currentUser, signOut} = useContext(AuthContext)
    const [signingOut, setSigningOut] = useState(false)

    const handleSignOut = async () => {
        setSigningOut(true)

        try {
            await timeout(1000)
            await signOut()
            setSigningOut(false)
            toast.error("Vous êtes désormais déconnecté.")
            setMenuOpen(false)
        } catch (error) {
            setSigningOut(false)
            toast.error("Une erreur est survenue.")
            console.log(error)
        }
    }

    useEffect(() => {
      if (isMenuOpen) {
          document.body.style.overflow = 'hidden';
      } else {
          document.body.style.overflow = 'unset';
      }
    }, [isMenuOpen, signingOut]);

    useEffect(() => {
        console.log(currentUser?.email)
    }, [currentUser]);

  return (
      <div className={`bg-white w-full h-screen font-roboto 2xl:m-auto`}>
      {/* <div className={`${isMenuOpen ? "overflow-hidden" : "overflow-visible"} bg-white w-full h-screen font-roboto 2xl:m-auto`}></div> */}
          <div className="h-full w-full">

              {/* First Section - Home */}

              <div className="flex flex-col lg:h-fit w-full overflow-hidden"> {/* First Section */}
                  <HeaderMenu
                      classNames=""
                      currentUser={currentUser}
                      isMenuOpen={isMenuOpen}
                      isHamburgerVisible={true}
                      isAddJanazaButtonVisible={true}
                      isSeeJanazaButtonVisible={true}
                      handleSignOut={handleSignOut}
                      setMenuOpen={setMenuOpen}
                  />
                  <div className={`flex bg-blue_sky flex-col justify-between h-full pt-32 lg:pt-52`}>
                      <div className="flex flex-col self-center">
                          <HeadTitle className="" text="“Chaque Âme Goûtera La Mort”" />
                          <p className={`
                              font-semibold text-primary_color_10 text-2xl text-center mb-7
                              lg:text-3xl
                          `}>(S2, V185)</p>
                          <h2 className={`
                              font-normal text-primary_color_10 self-center text-base text-center w-11/12 mb-5
                              lg:mb-7 lg:text-lg
                          `}>Revivifions une sunna qui profitera aux morts et aux vivants.</h2>
                          <div className={`
                              flex flex-col gap-2 self-center h-fit mb-12 w-11/12
                              lg:gap-5 lg:mb-16 lg:w-fit lg:flex-row
                          `}>
                              <PlainButton
                                  className={`
                                      w-full
                                      lg:w-fit lg:py-0
                                  `}
                                  text="Ajouter une Janaza"
                                  onClick={() => currentUser ? navigate("/add-prayer") : navigate("/signin")}
                              />
                              <BorderButton
                                  className={`
                                      w-full
                                      lg:w-fit
                                  `}
                                  small={false}
                                  text="Voir les Janaza"
                                  onClick={() => navigate("/prayers")}
                              /> {/* lg:py-0.5 because border of button is outisde. to make border inside button, we need to apply height, but the button doesn't have height. */}
                          </div>
                      </div>
                      <img src={phoneHomeCut} alt="" className="w-80 self-center"/>
                  </div>
              </div>

              {/* Second Section - A Propos */}

              <section className={`
                  flex flex-col bg-white w-full overflow-hidden max-w-screen-2xl
                  2xl:m-auto
              `}>
                  <div className={`
                      self-center mb-10 pt-20
                      xl:pt-32
                  `}>
                      <HeadTitle className="" text="Le Concept"/>
                  </div>

                  <div className={`
                      self-center flex flex-col w-11/12 pb-44
                      xl:mt-28 xl:flex-row
                  `}>
            <div className={`
                mb-44
                lg:flex lg:flex-col
                xl:mb-0 xl:pe-32 xl:flex-[1.5]
                xl:pt-5
            `}>
              <SecondaryTitle className="mb-6 w-full text-center xl:text-start" text="Qu'est-ce que la Salat Janaza"/>
              <p className="text-primary_color_10 mb-2 font-normal text-center text-lg whitespace-pre-line lg:text-xl lg:w-10/12 lg:self-center xl:text-start xl:w-full">{`La "`}<span className="font-medium">Salat Janaza</span>{`" est une prière que l'on effectue en commun pour chaque défunt musulman.\n\nC'est ce que l'on appele une obligation communautaire ("`}<span className="font-medium">Fard Kifayah - فـَرْضُ الكِفايَة</span>{`"), ce qui veut dire que si une partie des musulmans y assitent, le reste n'est pas obligé d'y assister.\n\nD'après 'Abdallah Ibn 'Omar (qu'Allah les agrée lui et son père), le Prophète (que la prière d'Allah et Son salut soient sur lui) a dit: « Il n'y a pas un homme sur qui 100 personnes prient (la prière mortuaire) sans qu'Allah ne le pardonne ».`}</p>
              <p className="text-grey_light font-normal text-end text-xs lg:text-base">Rapporté par Tabarani et authentifié par Cheikh Albani dans Sahih Al Jami n°5716</p>
              <p className="text-primary_color_10 mb-2 mt-8 font-normal text-center text-lg whitespace-pre-line lg:text-xl lg:w-10/12 lg:self-center xl:text-start xl:w-full">{`D'après Abdallah Ibn 'Abbas, le prophète a dit : « Il n'y a aucun musulman qui meurt et sur qui 40 personnes qui n'associent rien à Allah prient, (Salat al janaza) sans qu'Allah ne les fasse intercéder pour lui ».`}</p>
              <p className="text-grey_light font-normal text-end text-xs lg:text-base">Rapporté par Mouslim dans son Sahih n°948</p>
            </div>
            <PhoneWithBackground className="w-full self-center xl:flex-1" url={phoneHome}  color="bg-primary_color_10"/>
          </div>
        </section>

        <div className="h-0.5 w-11/12 m-auto bg-black bg-opacity-10"></div> {/* Horizontal Divider */}

        {/* Third Section - Application */}

        <section className="flex flex-col bg-white w-full"> {/* Third Section */}
          <div className="self-center mb-10 pt-20 xl:pt-36">
            <HeadTitle className="" text="L'Application"/>
          </div>

          <div className="flex flex-col self-center w-11/12 pb-44 xl:pt-28 xl:flex-row-reverse max-w-screen-2xl 2xl:m-auto"> {/* Add a Janaza Section */}
            <div className="mb-40 flex flex-col items-center xl:mb-0 xl:ps-32 xl:flex-[1.5] xl:pt-5 xl:items-start">
              <SecondaryTitle className="mb-6 text-center xl:text-start" text="Ajouter une Janaza"/>
              <p className="text-primary_color_10 mb-8 font-normal text-center text-lg whitespace-pre-line lg:text-xl lg:w-10/12 xl:text-start xl:w-full">{`Une interface simple et intuitive pour référencer les prières mortuaires.\n\nToucher un large nombre de musulmans et de musulmanes afin de maximiser le nombre de musulmans à chaque Janaza.\n\nContribuez au développement d'une pratique prophétique qui vous sera vous aussi profitable le jour de votre mort.`}</p>
              <a href = "https://play.google.com/store/apps/details?id=com.cdm.janaza"><img src={playStoreLogo} alt="" className="h-24"/></a>
            </div>
            <PhoneWithBackground className="w-full self-center xl:flex-1" url={phoneAddPrayer} color="bg-gold"/>
          </div>

          <div className="self-center w-full bg-blue_sky xl:px-0 xl:my-0 xl:flex-col xl:flex"> {/* Localize a Janaza Section */}
            <div className="flex flex-col pb-44 self-center w-full pt-10 xl:py-48 xl:w-11/12 xl:pb-48 xl:flex-row max-w-screen-2xl 2xl:m-auto">
              <div className="mb-40 flex flex-col items-center w-11/12 self-center xl:self-auto xl:items-start xl:mb-0 xl:pe-32 xl:flex-[1.5] xl:pt-5">
                <SecondaryTitle className="mb-6 w-full text-center xl:text-start" text="Localiser une Janaza"/>
                <p className="text-primary_color_10 mb-8 font-normal text-center text-lg whitespace-pre-line lg:text-xl lg:w-10/12  xl:text-start xl:w-full">{`Rendez vous aux janazas proches de chez vous, ou proches de l'endroit où vous êtes.\n\nParticipez à une janaza et engagez-vous à vous y rendre afin d'invoquer Allah en faveur du défunt ou de la défunte.`}</p>
                <a href = "https://play.google.com/store/apps/details?id=com.cdm.janaza"><img src={playStoreLogo} alt="" className="h-24"/></a>
              </div>
              <PhoneWithBackground className="w-11/12 self-center xl:flex-1" url={phoneMap} color="bg-primary_color"/>
            </div>
          </div>  

          <div className="flex flex-col self-center w-11/12 pb-44 pt-10 xl:py-48 xl:flex-row-reverse max-w-screen-2xl 2xl:m-auto"> {/* Learn Janaza Section */}
            <div className="mb-40 flex flex-col items-center xl:mb-0 xl:ps-32 xl:flex-[1.5] xl:pt-5 xl:items-start">
              <SecondaryTitle className="mb-6 w-full text-center xl:text-start" text="Apprendre"/>
              <p className="text-primary_color_10 mb-8 font-normal text-center text-lg whitespace-pre-line lg:text-xl lg:w-10/12 xl:text-start">{`Du contenu sur le déroulement des rites funéraires musulmans.\n\nServez-vous de ce que vous avez appris et appliquez le pour vous et pour vous proches.`}</p>
              <a href = "https://play.google.com/store/apps/details?id=com.cdm.janaza"><img src={playStoreLogo} alt="" className="h-24"/></a>
            </div>
            <PhoneWithBackground className="w-full self-center xl:flex-1" url={phoneLearn} color="bg-gold"/>
          </div>
        </section>
        <FooterJanaza className={``} onContactUs={() => undefined}/>
      </div>
      { signingOut &&
        <LoadingOverlay className=""/>
      }

      <MenuOverlay
        isMenuOpen={isMenuOpen}
        currentUser={currentUser}
        closeMenu={() => setMenuOpen(false)}
        setSigningOut={handleSignOut}
        navigateSignIn={() => navigate("/signin")}
        navigateSignUp={() => navigate("/signup")}
      />
      
    </div>
  );
}

export default App;
