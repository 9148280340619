import React, { useRef, useState } from "react";
import { PrayerFromFirebase as PrayerModel } from "../models/prayer";
import ChipAction from "./ChipAction";
import { DeleteOutlineRounded, DeleteRounded, DirectionsCarRounded, EqualizerRounded, ExpandMoreRounded, ImageRounded, LinkRounded, LocationOnRounded, MoreHorizRounded, SmsRounded, WcRounded } from "@mui/icons-material";
import { toast } from "sonner";
import html2canvas from "html2canvas";
import { User } from "firebase/auth";
import { useInView } from "react-intersection-observer";
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import useOnclickOutside from "react-cool-onclickoutside";

interface PrayerCardProps {
    prayer: PrayerModel,
    currentUser: User | null,
    className?: string,
    onDelete: (prayerId: string) => any,
    onPrayerViewed: (prayerId: string) => any
}

export const PrayerCardMobile = ({prayer, currentUser, className, onDelete, onPrayerViewed }: PrayerCardProps) => {

    const cardRef = useRef(null);

    const [isVisible, setVisibility] = useState(false);
    const [isMenuVisible, setMenuVisibility] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const toggleVisibility = () => {
        setVisibility(!isVisible)
    }

    const toggleMenuVisibility = (event: any) => {
        event.stopPropagation();
        setMenuVisibility(!isMenuVisible)
    }

    const shareLink = async (event: any) => {
        event.stopPropagation();
        await navigator.clipboard.writeText("janazaapp.fr/prayers");
        toast.success("Lien copié.");     
    }

    const handleDownloadImage = async (event: any) => {
        event.stopPropagation();

        if (cardRef.current != null) {
            const element = cardRef.current;
            const canvas = await html2canvas(element, {backgroundColor: "rgba(0, 0, 0, 0)"});

            const data = canvas.toDataURL('image/png', 1.0);

            const link = document.createElement('a');
            link.href = data;
            link.download = 'Janaza_' + prayer.name.replace(' ', '-') + '_' + prayer.date + '.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link.remove();
        }
        toast.success("Al Hamdulillah - Image téléchargée.");
    }

    const handleDeletePrayer = () => {
        if (prayer.user_id != currentUser?.uid)
            return

        if (prayer.id != null)
            onDelete(prayer.id)
    }

    const contextMenuRef = useOnclickOutside(() => {
        setMenuVisibility(!setMenuVisibility);
    });

    const { ref } = useInView({
        threshold: 0,
        onChange(inView, entry) {
            if (inView) {
                if (prayer.id != null)
                    onPrayerViewed(prayer.id)
                console.log(inView + " " + prayer.name)
            }
        }
    })

    return (
        <div key={prayer.id} ref={cardRef} className={`${className}`} onClick={toggleVisibility}>
            <div ref={ref}>
                <div className="flex flex-col h-full ">
                    <div className="py-1 px-2 flex flex-row rounded-t-lg justify-between bg-primary_color_40">
                        <div id={"date_and_hour"} className="flex flex-row">
                            <div className="flex flex-row items-center me-3">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[16px] h-[16px] me-2 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                                </svg>
                                <p className="text-sm text-white">{prayer.date}</p>
                            </div>
                            <div className="flex flex-row items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-[16px] h-[16px] me-2 text-white">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>
                                <p className="text-sm text-white">{`${prayer.prayName === "" ? `${prayer.hour}` : `${prayer.hour} après ${prayer.prayName}`}`}</p>
                            </div>
                        </div>
                        { (currentUser?.uid === prayer.user_id) &&
                            <div
                                onClick={(event: any) => toggleMenuVisibility(event)}
                                ref={contextMenuRef}
                                className="relative"
                            >
                                <MoreHorizRounded className="text-white"/>
                                <AlertDialog.Root open={openDialog} onOpenChange={setOpenDialog}>
                                    <AlertDialog.Trigger>
                                        <div className={`${isMenuVisible ? "h-10 w-44" : "h-0 w-44"} right-0 flex flex-row justify-center items-center overflow-hidden shadow-lg rounded-md absolute bg-white transition-all`}>
                                            <DeleteOutlineRounded className="text-red me-2"/>
                                            <p className="self-center text-red font-medium">Supprimer</p>
                                        </div>
                                    </AlertDialog.Trigger>
                                    <AlertDialog.Portal>
                                    <AlertDialog.Overlay onClick={() => setOpenDialog(false)} className="bg-black bg-opacity-50 data-[state=open]:animate-overlayShow fixed inset-0 z-50" />
                                    <AlertDialog.Content className="data-[state=open]:animate-contentShow fixed z-[51] top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                                        <AlertDialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                                        Voulez-vous supprimer cette Janaza ?
                                        </AlertDialog.Title>
                                        <AlertDialog.Description className="text-mauve11 mt-4 mb-5 text-[15px] leading-normal">
                                        La Janaza sera définitivement supprimée de la base de données.
                                        </AlertDialog.Description>
                                        <div className="flex justify-end gap-[25px]">
                                        <AlertDialog.Cancel asChild>
                                            <button className="text-primary_color_40 bg-blue_sky  hover:bg-mauve5 focus:shadow-mauve7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]">
                                            Non
                                            </button>
                                        </AlertDialog.Cancel>
                                        <AlertDialog.Action asChild>
                                            <button onClick={handleDeletePrayer} className="text-red bg-red bg-opacity-30 hover:bg-red5 focus:shadow-red7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]">
                                            Oui, supprimer
                                            </button>
                                        </AlertDialog.Action>
                                        </div>
                                    </AlertDialog.Content>
                                    </AlertDialog.Portal>
                                </AlertDialog.Root>
                            </div>
                        }
                    </div>
                    <div className="flex flex-col px-3 pt-2 pb-3 rounded-b-lg bg-grey_30 h-full transition-all">
                        <div>
                            <p className="text-lg font-medium text-primary_color_10">{prayer.name.charAt(0).toUpperCase() + prayer.name.slice(1)}</p>
                            <p className="text-base font-regular mb-2 text-primary_color_10  line-clamp-2">{prayer.address}</p>
                        </div>
                        {/* <div className="h-full"></div> */}
                        <div className="flex flex-row gap-2 self-start ">
                            <ChipAction text="Partager" onClick={(event: any) => shareLink(event)} className="hover:shadow-md transition-shadow"
                                icon={<LinkRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>}
                            />
                            <ChipAction text="Enregistrer" onClick={(event: any) => handleDownloadImage(event)} className="hover:shadow-md transition-shadow"
                                icon={<ImageRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>}
                            />
                        </div>
                        <div className={`${isVisible ? "block" : "hidden"} pt-3 flex flex-col `}>
                            <div className="flex flex-row border-t-2 py-2 border-grey_40">
                                <WcRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>
                                <p className="text-sm self-center font-light text-grey_primary_color_10">{prayer.sexe === "Je ne sais pas" ? "Aucune infos sur le sexe" : prayer.sexe }</p>
                            </div>
                            <div className="flex flex-row border-t-2 py-2 border-grey_40">
                                <SmsRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>
                                <p className="text-sm self-center font-light text-grey_primary_color_10 whitespace-pre-line">{
                                    `Qu'Allah lui pardonne et lui fasse miséricorde.\nInna lillah wa inna ilayhi raji'un.\nCourage à la famille et à ses proches\n${prayer.moreInfos}`
                                }</p>
                            </div>
                            <div className="flex flex-row border-t-2 py-2 border-grey_40 items-center">
                                <LocationOnRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>
                                <p className="text-sm font-light text-grey_primary_color_10 whitespace-pre-line">{
                                    `${prayer.address}`
                                }</p>
                            </div>
                            <div className="flex flex-row border-t-2 pt-2 border-grey_40 items-center">
                                <DirectionsCarRounded className="w-[18px] h-[18px] me-2 text-primary_color"/>
                                <div className="flex flex-col h-fit">
                                    <p className={`text-sm ${prayer.convoi === "Oui" ? "text-gold" : "text-primary_color_10"} font-light`}>{
                                        `${prayer.convoi === "Je ne sais pas" ? "Convoi incertain" : prayer.convoi}`
                                    }</p>
                                    { prayer.convoi === "Oui" &&
                                        <p className="text-sm font-light text-grey_primary_color_10">{`${prayer.addressConvoi} ${prayer.hourConvoy === undefined ? "" : `à ${prayer.hourConvoy}`}`}</p>
                                    }
                                </div>
                            </div>
                            
                            {/* <div className="h-full"></div> */}
                        </div>
                        {/* <ExpandMoreRounded className={`${isVisible ? "rotate-180" : "rotate-0"} self-center text-primary_color`}/> */}
                        <div className="flex flex-row self-end justify-end">
                            <EqualizerRounded className="text-grey_light self-center text-lg" fontSize="small"/>
                            <p className="text-grey_light text-base font-normal self-center">{prayer.view_count}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}