import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

import PlainButton from "../../components/buttons/PlainButton";
import SecondaryTitle from "../../components/titles/SecondaryTitle";
import { forgotPassword, signInUser } from "../../data/repository/firebaseAuth";
import { toast } from "sonner";
import { AuthContext } from "../../context/AuthContext";
import FooterJanaza from "../../components/commons/FooterJanaza";
import TwoHeaderMenu from "../../components/navigation/headerMenu/TwoHeaderMenu";
import { LoadingOverlay } from "../../components/commons/LoadingOverlay";

const defaultFormFields = {
    email: '',
    password: '',
}

const SignIn: React.FunctionComponent = () => {
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [formFields, setFormFields] = useState(defaultFormFields)
    const { email, password } = formFields
    const [authing, setAuthing] = useState(false);

    const [resetPassword, setResetPassword] = useState(false);

    useEffect(() => {
        if (currentUser) {
            navigate('/');
        }
    })

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setAuthing(true)
        try {
            await timeout(1000)
            await signInUser(email, password)
                .then(async (credential) => {
                    setAuthing(false)
                    toast.success("As Salam Aleykoum " + credential?.user.email)
                })
        } catch (error) {
            setAuthing(false)
            toast.error("Connexion impossible, veuillez réessayez")
            console.log("User sign in failed", error)
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setFormFields({...formFields, [name]: value })
    }

    const handleForgotPassword = async () => {
        const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

        if (email.length === 0 || !emailRegex.test(email)) {
            toast.error("Pour récupérer votre mot de passe, saisissez une adresse email valide dans le champ 'Email'");
            return;
        }

        setResetPassword(true)

        try {
            await forgotPassword(email);
            setResetPassword(false);
            toast.success("Vous recevrez un email de récupération de mot de passe d'ici quelques minutes insha'Allah");
        } catch (error) {
            setResetPassword(false);
            toast.error("Une erreur est survenue, veuillez vérifier votre adresse mail");
        }
    }

    return (
        <div className="bg-white w-full h-screen font-roboto 2xl:m-auto relative">
            <section className="flex flex-col lg:h-screen w-full overflow-hidden items-center bg-blue_sky"> {/* First Section */}
                <TwoHeaderMenu
                    classNames=""
                    currentUser={currentUser}
                    isSignUpButtonVisible={true}
                />
                <div className="flex flex-col h-screen pt-32 w-11/12 lg:p-0 lg:justify-center lg:w-full lg:max-w-sm">
                    <SecondaryTitle
                        text="Se connecter"
                        className="mb-1"
                    />
                    <h3 className="text-start text-grey_light mb-6">
                        Connectex-vous pour ajouter une Salat Janaza et pour accéder à encore plus de fonctionnalités par la suite.
                    </h3>
                    <form
                        onSubmit={handleSubmit}
                        className="mb-2"
                    >
                        <input
                            required
                            placeholder="Email"
                            type="email"
                            name="email"
                            value={email}
                            onChange={handleChange}
                            className={`${email != "" ? "border-primary_color" : "border-white"} text-base w-full mb-2 py-3 box-border placeholder:text-grey_light outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg`}
                        />
                        <input
                            required
                            placeholder="Mot de passe"
                            type="password"
                            name="password"
                            value={password}
                            onChange={handleChange}
                            className={`${password != "" ? "border-primary_color" : "border-white"} text-base w-full mb-2 py-3 box-border placeholder:text-grey_light outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg`}
                        />
                        <PlainButton
                            type="submit"
                            text="Continuer"
                            className="mt-2 w-full"
                        />
                    </form>
                    <button 
                        onClick={handleForgotPassword}
                        className="self-center"
                    >
                        <p className="text-xs my-2 text-black underline">
                            {"J'ai oublier mon mot de passe"}
                        </p>
                    </button>
                    <p className="text-xs my-4 text-start text-black text-opacity-60">
                        {`En me connectant, j'accepte les `}
                        <a
                            href="https://pages.flycricket.io/janaza/privacy.html"
                            className=""
                        >
                            <span className="underline">
                                règles de confidentialités
                            </span>
                        </a>
                    </p>
                </div>
            </section>
            <FooterJanaza
                className={``}
                onContactUs={() => undefined}
            />
            { (authing || resetPassword) &&
                <LoadingOverlay className=""/>
            }
        </div>
    );
}

function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

export default SignIn;