import React from 'react';

interface InfoBarHeaderMenuProps {
    count: number,
} 

const InfoBarHeaderMenu = ({count}: InfoBarHeaderMenuProps) => {
    return (
        <div className='h-8 md:h-9 w-full bg-primary_color ps-4 flex'>
            <span className="relative flex h-2 w-2 me-2 self-center">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary_color_90 self-center me-2"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-primary_color_10 bg-opacity-40"></span>
            </span>
            <p className='self-center font-normal text-base text-white'>{`${count + 160} Janaza inscrit | Al Hamdulillah`}</p>
        </div>
    )
}

export default InfoBarHeaderMenu;