import React from 'react';

import janazaInline from "../../styles/drawable/janaza_inline.png"
import janazaLogo from "../../styles/drawable/janaza_logo.png";
import { Icon } from '@iconify/react';

import { useNavigate } from 'react-router-dom';

interface FooterJanazaProps {
    className?: string,
    onContactUs: () => any
}

const FooterJanaza = ({className, onContactUs}: FooterJanazaProps) => {

    const navigate = useNavigate()

    return (
        <footer className={`${className} flex flex-col items-center py-9 px-10 bg-primary_color_10`}>
            <div className='flex flex-row pb-9'>
                <img src={janazaLogo} alt="" className="h-7 me-1"/>
                <img src={janazaInline} alt="" className="h-7 me-1"/>
            </div>
            <button onClick={onContactUs} className="text-white self-center font-normal text-base rounded-full hover:bg-black hover:bg-opacity-10 transition-colors mb-6 px-4 py-3">Nous Contacter</button>
            <div className='flex flex-row gap-5 mb-10'>
                <a href={"https://twitter.com/prierejanaza"} className='rounded-full flex justify-center items-center size-16 bg-white bg-opacity-20 transition-all hover:bg-opacity-35'>
                    <Icon icon="pajamas:twitter" color='white' fontSize={24} className=''/>
                </a>
                <a href={"https://www.instagram.com/prierejanaza/"} className='rounded-full flex justify-center items-center size-16 bg-white bg-opacity-20 transition-all hover:bg-opacity-35'>
                    <Icon icon="mdi:instagram" color='white' fontSize={26}/>
                </a>
                <a href={"https://play.google.com/store/apps/details?id=com.cdm.janaza"} className='rounded-full flex justify-center items-center size-16 bg-white bg-opacity-20 transition-all hover:bg-opacity-35'>
                    <Icon icon="bxl:play-store" color='white' fontSize={28}/>
                </a>
            </div>
            <div className='h-[0.5px] mb-9 w-full bg-primary_color_90 bg-opacity-20'></div>
            <p className='text-white font-normal text-base'>©2023 Janaza - Tous droits réservés.</p>
        </footer>
    )
}

export default FooterJanaza;